import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../layouts"

import LocalizedLink from "../components/LocalizedLink"

import { smartReplacements } from "../utils/text-properties"

import PlaceholderCookieConsentReset from "../components/PlaceholderCookieConsentReset"
import PlaceholderEmailAddress from "../components/PlaceholderEmailAddress"
import PlaceholderPostalAddress from "../components/PlaceholderPostalAddress"
import PlaceholderVideo from "../components/PlaceholderVideo"

const MediaPage = props => {
  const {
    pageContext: { locale },
    data: {
      allContentfulMediaPage: { nodes: pages },
    },
  } = props

  /**
   * get localized page with smart replacements
   * @param {Array} pages
   * @param {String} locale
   * @returns
   */
  const getLocalizedPage = (pages, locale) => {
    return pages
      .map(page => ({
        ...page,
        pageFooterCtaLabel: smartReplacements(page.pageFooterCtaLabel, locale),
        pageTitle: smartReplacements(page.pageTitle, locale),
      }))
      .find(page => page.node_locale === locale)
  }

  const localizedPage = getLocalizedPage(pages, locale)

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   *
   * The `scrollMarginTop` property ensures that the headline is in the field of view.
   * More details: https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
   */
  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <LocalizedLink to={`/${node.data.target.pageSlug}`}>
            {node.content[0].value}
          </LocalizedLink>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.component === "Cookie Consent Reset") {
          return <PlaceholderCookieConsentReset />
        }
        if (node.data.target.component === "Email Address") {
          return <PlaceholderEmailAddress />
        }
        if (node.data.target.component === "Postal Address") {
          return <PlaceholderPostalAddress />
        }
        if (node.data.target.component === "Video") {
          const videoTitle = node.data.target.videoTitle || null
          const videoUrl = node.data.target.videoUrl || null
          return <PlaceholderVideo title={videoTitle} url={videoUrl} />
        }
        return <React.Fragment></React.Fragment>
      }
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  return (
    <Layout title={localizedPage.pageTitle} description={""}>
      <section className="section has-background-paper has-background-paper-top has-tree-paper has-tree-paper-middle">
        <div className="container">
          {/* title */}
          <div className="columns">
            <div className="column is-8">
              <h1 className="title my-6">{localizedPage.pageTitle}</h1>
            </div>
          </div>

          {/* header image */}
          <div className="columns is-unspaced">
            <div className="column is-6 is-offset-2">
              <GatsbyImage
                objectFit="contain"
                loading="eager"
                alt="Marén Schaake"
                image={localizedPage.pageHeaderImage.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </section>

      {/* header content */}
      <section className="section">
        <div className="container">
          <div className="columns is-unspaced">
            <div className="column is-6 is-offset-2 content">
              {localizedPage.pageHeaderContent &&
                renderRichText(localizedPage.pageHeaderContent, renderOptions)}
            </div>
          </div>
        </div>
      </section>

      {localizedPage.pageSections.map((section, index) => {
        const sectionContent = (
          <React.Fragment>
            <h3 className="title">{section.sectionTitle}</h3>
            {section.sectionContent &&
              renderRichText(section.sectionContent, renderOptions)}
            {section.sectionCtaLabel && section.sectionCtaDestination && (
              <Link
                className="button is-special"
                to={section.sectionCtaDestination}
              >
                {section.sectionCtaLabel}
              </Link>
            )}
          </React.Fragment>
        )

        const sectionImages =
          section && section.sectionImages
            ? (
              <div className="image-stack-wrapper">
                <div className="image-stack">
                  <div className="image-stack-item-top">
                    <GatsbyImage
                      objectFit="contain"
                      loading="lazy"
                      alt=""
                      image={section.sectionImages[0].gatsbyImageData}
                    />
                  </div>
                  {section.sectionImages[1] && (<div className="image-stack-item-bottom">
                    <GatsbyImage
                      objectFit="contain"
                      loading="lazy"
                      alt=""
                      image={section.sectionImages[1].gatsbyImageData}
                    />
                  </div>)}
                </div>
              </div>
            )
            : <React.Fragment></React.Fragment>

        let sectionType = section.sectionType
        if (section.sectionType === "Auto") {
          sectionType = index % 2 === 0 ? "Left-aligned" : "Right-aligned"
        }

        const reversed =
          sectionType === "Left-aligned" ? "" : " is-reversed-mobile"
        const background =
          sectionType === "Left-aligned"
            ? "has-background-paper-right"
            : "has-background-paper-left"
        const hasTreeLeft =
          sectionType === "Left-aligned" ? " has-tree-paper-left" : ""

        return (
          <section
            key={index}
            className={`section ${background}${hasTreeLeft}`}
          >
            <div className="container">
              <div className={`columns is-unspaced${reversed}`}>
                {sectionType === "Left-aligned" && (
                  <React.Fragment>
                    <div className="column content is-4 is-offset-2 my-0 mb-4">
                      {sectionContent}
                    </div>
                    <div className="column is-5 is-offset-1 my-0 is-relative">
                      {sectionImages}
                    </div>
                  </React.Fragment>
                )}
                {sectionType === "Right-aligned" && (
                  <React.Fragment>
                    <div className="column is-5 my-0 is-relative">
                      {sectionImages}
                    </div>
                    <div className="column content is-4 is-offset-1 my-0 mb-4">
                      {sectionContent}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
        )
      })}

      {/* footer content */}
      <section className="section my-6">
        <div className="container">
          <div className="columns is-unspaced">
            <div className="column is-6 is-offset-2 content">
              {localizedPage.pageFooterContent &&
                renderRichText(localizedPage.pageFooterContent, renderOptions)}
              {localizedPage.pageFooterCtaLabel && localizedPage.pageFooterCtaDestination && (
                <Link
                  className="button is-special"
                  to={localizedPage.pageFooterCtaDestination}
                >
                  {localizedPage.pageFooterCtaLabel}
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <pre>{JSON.stringify(pages, null, 2)}</pre> */}
    </Layout>
  )
}

export default MediaPage

export const MediaPageQuery = graphql`
  query ($contentfulId: String!) {
    allContentfulMediaPage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        pageTitle
        pageHeaderImage {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1280
            quality: 80
            breakpoints: [480, 768, 1024, 1216, 1408]
            formats: [AUTO, WEBP]
          )
        }
        pageHeaderContent {
          raw
          references {
            ... on ContentfulPlaceholder {
              contentful_id
              component
              videoTitle
              videoUrl
            }
          }
        }
        pageSections {
          contentful_id
          node_locale
          sectionTitle
          sectionType
          sectionCtaLabel
          sectionCtaDestination
          sectionContent {
            raw
            references {
              ... on ContentfulPlaceholder {
                contentful_id
                component
                videoTitle
                videoUrl
              }
            }
          }
          sectionImages {
            id
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 80
              width: 400
              aspectRatio: 0.75
              breakpoints: [200, 300, 400]
            )
          }
        }
        pageFooterContent {
          raw
          references {
            ... on ContentfulPlaceholder {
              contentful_id
              component
              videoTitle
              videoUrl
            }
          }
        }
        pageFooterCtaLabel
        pageFooterCtaDestination
      }
    }
  }
`
